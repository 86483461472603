<template>
  <div id="app-layout" class="container-fluid">
    <div class="row">
      <SideBar v-if="showSideBar"></SideBar>
      <main class="main-content">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import SideBar from '@/components/SideBar'

export default {
  components: {
    SideBar
  },

  computed: {
    ...mapState('auth', [
      'userAuth'
    ]),

    showSideBar() {
      return this.userAuth.length > 0
    }
  },

  methods: {
    ...mapActions('schools', [
      'getSchools'
    ]),

    ...mapActions('payrolls', [
      'getPayrolls',
      'getPendingPayrolls'
    ]),

    ...mapActions('employees', [
      'getEmployees'
    ]),
  },

  async mounted () {
    let form = new FormData()
    form.append('token', this.userAuth)
    await this.getSchools(form)
    await this.getEmployees(form)
    await this.getPayrolls(form)
    await this.getPendingPayrolls(form)
  }
}
</script>
